// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import DataTable from 'Ui/components/common/data-table/data-table';
import EditLink from 'Ui/components/common/data-table/edit-link';
import AdditionalActionButton from 'OnEquip/equipment/common/additional-action-button';
import ServiceCheckbox from 'OnEquip/equipment/maintenance/data-table/service-checkbox';
import EquipmentName from 'OnEquip/equipment/common/data-table/equipment-name';
import OnLinkProgressBar from 'Ui/components/common/onlink-progress-bar';
import {deleteService, skipService} from 'Services/maintenance-service';
import {getMaintenanceDue} from 'Services/membership-service';
import {fetchEffectData} from 'Utils/react-utils';
import {
    alphaNumericCompare,
    dateCompare,
    dueColumnCompare,
    formatDateSelect,
    formatEquipmentName,
    formatModelAndManufacturerName,
    genericServiceCompare,
    getInterval,
    getPercentClassName,
    getPercentDue,
    numericCompare
} from 'Ui/models/maintenance';
import {closeDialog, openDialog} from 'Store/actions/dialogs';
import {addToast} from 'Store/actions/toasts';
import accounting from 'accounting';
import dialogTypes from 'Ui/components/common/dialog-types';
import {useEquipmentContext} from 'OnEquip/equipment/context/equipment-context';
import {MAINTENANCE} from 'OnEquip/equipment/constants/equipment-details-tabs';
import permissionsConstants from 'Common/constants/permissions';
import {RECOMMENDED} from 'Common/constants/service-status';
import {hasPermissions} from 'Common/utils/permission-handler';
import {SUGGESTED_MAINTENANCE} from 'Common/constants/routes';
import {ONLINK_NAVIGATION_REDESIGN} from 'Common/constants/feature-toggles';
import {
    createOnContinueCallback, displayErrorMessage,
    getDeleteSelectedServiceConfirmationMessage,
    modifyServices
} from 'Utils/maintenance-utils';
import {useNavBarActions} from 'Ui/react-hooks/use-navbar-actions';
import {PARTS_CATALOG_URL, SHOP_URL} from 'Ui/components/secondary-navbar/utils/dropdown-navbar-helper';
import {redirect} from 'Utils/redirect-utils';
import {
    EDIT_SERVICE,
    MANAGE_EQUIPMENT_SETTINGS,
    MANAGE_SERVICE,
    VIEW_SERVICE
} from 'Common/constants/business-activities';
import {isShopDeereUrlEnabled} from 'Src/ui-core/pages/onequip/parts/inventory';

const DECIMAL_TO_PERCENT = 100;

function rowAccessor(row) {
    return row;
}

function getColumns(translations, membershipId, selectedServices, setSelectedServices, canDeleteServices, history) {
    const onequipUrl = `${window.props.onLinkLegacyUri}/onequip/#m/${membershipId}/fleet/{0}/suggestedMaintenance`;

    const equipmentUrl = `${onequipUrl}/equipment/{1}`;
    const serviceUrl = `${SUGGESTED_MAINTENANCE}/serviceForm?serviceId={0}`;

    const columns = [
        {
            Header: translations.EQUIPMENT_LIST,
            accessor: rowAccessor,
            id: 'formattedName',
            sortMethod(a, b) {
                return genericServiceCompare(a, b, alphaNumericCompare, 'formattedName');
            },
            Cell(row) {
                return (
                    <EquipmentName
                        equipment={row.original}
                        tab={MAINTENANCE}
                        type={SUGGESTED_MAINTENANCE}
                        url={equipmentUrl}
                    />
                );
            }
        },
        {
            Header: translations.MODEL,
            accessor: rowAccessor,
            id: 'modelAndManufacturerName',
            sortMethod(a, b) {
                return genericServiceCompare(a, b, alphaNumericCompare, 'modelAndManufacturerName');
            },
            Cell(row) {
                return row.original.modelAndManufacturerName;
            }
        },
        {
            Header: translations.INTERVAL,
            accessor: rowAccessor,
            id: 'interval',
            sortMethod(a, b) {
                return genericServiceCompare(a, b, alphaNumericCompare, 'interval');
            },
            Cell(row) {
                return row.original.interval;
            }
        },
        {
            Header: translations.ONLINK_SERVICE,
            accessor: rowAccessor,
            id: 'serviceTypeName',
            sortMethod(a, b) {
                return genericServiceCompare(a, b, alphaNumericCompare, 'serviceTypeName');
            },
            Cell(row) {
                const service = row.original;
                const filledInServiceUrl = serviceUrl.replace('{0}', service.serviceId);

                return (
                    <EditLink
                        onClick={() => history.push(filledInServiceUrl)}
                        requiredMyJdPermissions={[VIEW_SERVICE]}
                    >
                        {service.serviceTypeName}
                    </EditLink>
                );
            }
        },
        {
            Header: translations.ONLINK_DUE_DATE,
            accessor: rowAccessor,
            id: 'formattedAtDateSelect',
            width: 150,
            sortMethod(a, b) {
                return genericServiceCompare(a, b, dateCompare, 'atDateSelect');
            },
            Cell(row) {
                return row.original.formattedAtDateSelect;
            }
        },
        {
            Header: translations.ONLINK_DUE_HOURS,
            accessor: rowAccessor,
            id: 'atHours',
            className: 'right-aligned',
            headerClassName: 'right-aligned',
            width: 150,
            sortMethod(a, b) {
                return genericServiceCompare(a, b, numericCompare, 'atHours');
            },
            Cell(row) {
                return row.original.atHours;
            }
        },
        {
            Header: translations.ONLINK_CURRENT_HOURS,
            accessor: rowAccessor,
            id: 'totalHours',
            className: 'right-aligned',
            headerClassName: 'right-aligned',
            width: 150,
            sortMethod(a, b) {
                return genericServiceCompare(a, b, numericCompare, 'totalHours');
            },
            Cell(row) {
                return accounting.formatNumber(row.original.totalHours, 1);
            }
        },
        {
            Header: translations.DUE,
            accessor: rowAccessor,
            id: 'dueProgressBar',
            className: 'center',
            width: 100,
            Cell(row) {
                const progress = row.original.percentDue;
                const progressClassName = getPercentClassName(row.original);

                return (
                    <OnLinkProgressBar
                        barClassName='maintenance-progress-bar'
                        fillClassName={progressClassName}
                        percentFilled={Math.min(1, progress) * DECIMAL_TO_PERCENT}
                    />
                );
            },
            sortMethod: dueColumnCompare
        }
    ];

    if (canDeleteServices) {
        columns.unshift({
            id: 'serviceSelected',
            className: 'left-aligned',
            headerClassName: 'right-aligned',
            width: 25,
            resizable: false,
            sortable: false,
            requiredMyJdPermissions: MANAGE_SERVICE,
            Cell(row) {
                const service = row.original;

                return (
                    <ServiceCheckbox
                        selectedServices={selectedServices}
                        serviceId={service.serviceId}
                        setSelectedServices={setSelectedServices}
                    />
                );
            }
        });
    }

    return columns;
}

function initializeState() {
    const [services, setServices] = React.useState(() => []);
    const [loading, setLoading] = React.useState(() => false);
    const [selectedServices, setSelectedServices] = React.useState(() => new Set());

    return {
        services,
        setServices,
        loading,
        setLoading,
        selectedServices,
        setSelectedServices
    };
}

function getNavBarActions(openServiceDialog, translations, loading, selectedServices,
    onSkipServicesClick, onDeleteServicesClick, history, featureToggles, isMigrated, country) {
    return [
        {
            onClick: () => openServiceDialog({
                translations
            }),
            disabled: loading,
            Icon: 'icon-add',
            title: 'ONLINK_SERVICE',
            variant: 'primary',
            requiredMyJdPermissions: EDIT_SERVICE
        },
        {
            disabled: selectedServices.size === 0,
            onClick: onSkipServicesClick,
            title: 'ONLINK_SKIP_SELECTED',
            requiredMyJdPermissions: MANAGE_SERVICE
        },
        {
            disabled: selectedServices.size === 0,
            onClick: onDeleteServicesClick,
            Icon: 'icon-delete',
            title: 'ONLINK_DELETE_SELECTED',
            requiredMyJdPermissions: MANAGE_SERVICE
        },
        {
            onClick: () => window.open(isShopDeereUrlEnabled(featureToggles, {
                country,
                isMigrated
            }) ? SHOP_URL : PARTS_CATALOG_URL, '_blank'),
            title: 'ONLINK_PARTS_CATALOG',
            Icon: 'icon-new-window'
        },
        {
            onClick: () => redirect(`${SUGGESTED_MAINTENANCE}/settings/fleet`, history),
            title: 'SETTINGS',
            Icon: 'icon-gear',
            requiredMyJdPermissions: MANAGE_EQUIPMENT_SETTINGS
        }
    ];
}

function getFilterComponent(
    translations,
    hasSelectedServices,
    openConfirmation,
    closeConfirmation,
    onDelete,
    onSkip,
    featureToggles,
    openServiceDialog,
    selectedServices,
    loading,
    history,
    isMigrated,
    country
) {
    const sharedConfirmationProps = {
        translations,
        onCancel: closeConfirmation,
        showLoadingIcon: true
    };

    const onDeleteServicesClick = () => {
        openConfirmation({
            ...sharedConfirmationProps,
            message: getDeleteSelectedServiceConfirmationMessage(translations),
            onContinue: createOnContinueCallback(closeConfirmation, onDelete),
            title: translations.DELETE_MANTENANCE
        });
    };

    const onSkipServicesClick = () => {
        openConfirmation({
            ...sharedConfirmationProps,
            message: translations.ONLINK_MAINTENANCE_SKIP_CONFIRMATION_MESSAGE,
            onContinue: createOnContinueCallback(closeConfirmation, onSkip),
            title: translations.ONLINK_SKIP_MAINTENANCE
        });
    };

    useNavBarActions(featureToggles[ONLINK_NAVIGATION_REDESIGN] ? getNavBarActions(
        openServiceDialog,
        translations,
        loading,
        selectedServices,
        onSkipServicesClick,
        onDeleteServicesClick,
        history,
        featureToggles,
        isMigrated,
        country
    ) : []);

    const additionalActionButtons = (
        <>
            <AdditionalActionButton
                className='delete-selected'
                disabled={!hasSelectedServices}
                label={translations.ONLINK_DELETE_SELECTED}
                onClick={onDeleteServicesClick}
            />
            <AdditionalActionButton
                className='skip-selected'
                disabled={!hasSelectedServices}
                label={translations.ONLINK_SKIP_SELECTED}
                onClick={onSkipServicesClick}
            />
        </>
    );

    return (
        <div className='extra-table-options right-aligned'>
            <div className='table-buttons'>
                {!featureToggles[ONLINK_NAVIGATION_REDESIGN] && additionalActionButtons}
            </div>
        </div>
    );
}

function Maintenance(props) {
    const {
        addToast,
        closeConfirmation,
        featureToggles,
        history,
        isMigrated,
        membershipId,
        openConfirmation,
        permissions,
        superUser,
        translations,
        openServiceDialog,
        country
    } = props;

    const {
        services,
        setServices,
        loading,
        setLoading,
        selectedServices,
        setSelectedServices
    } = initializeState(props);

    const equipmentContext = useEquipmentContext();

    const canDeleteServices = isMigrated || hasPermissions(
        {
            permissionMap: permissions,
            superUser
        },
        permissionsConstants.DELETE_SERVICE_TICKETS
    );

    equipmentContext.current = async (isMounted = () => true) => {
        setSelectedServices(new Set());
        setLoading(true);

        const services = await getMaintenanceDue(RECOMMENDED);

        if (isMounted()) {
            const formattedServices = services.map((service) => ({
                ...service,
                percentDue: getPercentDue(service),
                interval: getInterval(service, translations),
                modelAndManufacturerName: formatModelAndManufacturerName(service),
                formattedName: formatEquipmentName(service),
                formattedAtDateSelect: formatDateSelect(service)
            }));

            setServices(formattedServices);
            setLoading(false);
        }
    };

    async function onDelete() {
        const rejectedResults = await modifyServices(services, selectedServices, deleteService);

        if (rejectedResults.length !== 0) {
            const rejectedNames = rejectedResults.join(', ');

            displayErrorMessage(addToast, translations.ONLINK_MAINTENANCE_DELETE_ERROR, rejectedNames);
        }

        await equipmentContext.current();
    }

    async function onSkip() {
        const rejectedResults = await modifyServices(
            services,
            selectedServices,
            (serviceId) => skipService(serviceId)
        );

        if (rejectedResults.length !== 0) {
            const rejectedNames = rejectedResults.join(', ');

            displayErrorMessage(addToast, translations.ONLINK_MAINTENANCE_SKIP_ERROR, rejectedNames);
        }

        await equipmentContext.current();
    }

    React.useEffect(() => fetchEffectData(equipmentContext.current), [membershipId]);

    const filterComponent = canDeleteServices ? getFilterComponent(
        translations,
        selectedServices.size > 0,
        openConfirmation,
        closeConfirmation,
        onDelete,
        onSkip,
        featureToggles,
        openServiceDialog,
        selectedServices,
        loading,
        history,
        isMigrated,
        country
    ) : null;

    return (
        <DataTable
            columns={getColumns(translations, membershipId, selectedServices, setSelectedServices, canDeleteServices, history)}
            defaultSorted={[{
                desc: false,
                id: 'dueProgressBar'
            }]}
            filterComponent={filterComponent}
            loading={loading}
            rows={services}
            searchable={true}
            translations={translations}
        />
    );
}

Maintenance.propTypes = {
    addToast: PropTypes.func,
    closeConfirmation: PropTypes.func,
    closeDialog: PropTypes.func,
    country: PropTypes.string,
    featureToggles: PropTypes.featureToggles,
    history: PropTypes.history,
    isMigrated: PropTypes.bool,
    membershipId: PropTypes.string,
    openConfirmation: PropTypes.func,
    openDialog: PropTypes.func,
    openServiceDialog: PropTypes.func,
    permissions: PropTypes.legacyPermissions,
    superUser: PropTypes.bool,
    translations: PropTypes.translations,
    updateOnDeleteClick: PropTypes.func,
    updateOnSkipClick: PropTypes.func
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        isMigrated: state.membership.isMigrated,
        membershipId: state.membership.membershipId,
        permissions: state.account.permissions,
        superUser: state.account.superUser,
        country: state.membership.country
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        openServiceDialog(props) {
            dispatch(openDialog(dialogTypes.SERVICE_FORM_DIALOG, props));
        },
        addToast(value) {
            dispatch(addToast(value));
        },
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        },
        openConfirmation(props) {
            dispatch(openDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Maintenance));
