// Unpublished Work © 2021-2024 Deere & Company.

import {Workbook} from 'exceljs';
import {autofitWorksheet, downloadExcelFile} from 'Utils/excel-utils';
import moment from 'moment';
import accounting from 'accounting';
import {sortBy} from 'lodash';
import {alphaNumericCompare, dateCompare, defaultSortMethod} from 'Ui/models/maintenance';
import {
    capitalizeFirstLetter,
    replaceTranslationNames
} from 'Utils/translation-utils';
import {dataTableFormatTime} from 'Utils/time-utils';
import {formatNumber} from 'Utils/unit-conversion-utils';
import {UNITS} from 'Common/constants/units/unit-config-constants';
import {getHeaderColumnName} from 'Ui/features/onequip/reports/uptime-report/uptime-report-data-table';
import {INVENTORIES} from 'Ui/components/graph/constants/graph-filters';
import {EXPORT_FAIL} from 'Common/constants/errors';
import {translateErrorMessage} from '../utils/error-utils';
import {TOAST_TYPE} from '@deere/toast';

const HOURS_COLUMN = 8;
const EST_HRS_COLUMN = 6;
const ACT_HRS_COLUMN = 7;

function formatTwoDecimalPlaces(value) {
    return formatNumber(value, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}

async function exportFleetReport(fleet, translations, addToast) {
    try {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(translations.EQUIPMENT_LIST, {
            views: [
                {
                    state: 'frozen',
                    ySplit: 1
                }
            ]
        });

        const header = worksheet.addRow([
            translations.NAME,
            translations.TYPE,
            translations.AREA,
            translations.BRAND,
            translations.MODEL,
            translations.ONLINK_SERIAL_NUMBER,
            translations.STATUS,
            translations.HOURS
        ]);

        header.font = {
            bold: true
        };

        worksheet.getColumn(HOURS_COLUMN).style.numFmt = '#,##0.0';

        const rows = fleet.map((equipment) => [
            equipment.equipmentName,
            equipment.equipmentType,
            equipment.equipmentArea,
            equipment.manufacturerName,
            equipment.modelDescription,
            equipment.serialNumber,
            equipment.formattedStatus,
            accounting.parse(equipment.totalHours)
        ]);

        worksheet.addRows(rows);

        autofitWorksheet(worksheet);

        await downloadExcelFile(
            workbook,
            `Fleet-Export${moment.utc().format('YYYY-MM-DD')}.xlsx`
        );
    } catch (error) {
        addToast({
            message: translateErrorMessage(`${EXPORT_FAIL}`, translations),
            type: TOAST_TYPE.ERROR
        });
    }
}

async function exportWorkboardReport(workboardReport, translations, addToast) {
    try {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(
            translations.ONLINK_WORKBOARD_JOB_LIST,
            {
                views: [
                    {
                        state: 'frozen',
                        ySplit: 1
                    }
                ]
            }
        );

        const header = worksheet.addRow([
            translations.OPERATOR,
            translations.DATE,
            translations.ONLINK_WORKBOARD,
            translations.jobs_job,
            translations.IWP_EQUIPMENT_LABEL,
            translations.ONLINK_ROUTE,
            translations.ONLINK_MOWING_DIRECTIONS,
            translations.ONLINK_EST_HRS,
            translations.ONLINK_ACT_HRS,
            translations.ONLINK_PRE_START,
            translations.NOTE
        ]);

        header.font = {
            bold: true
        };

        worksheet.getColumn(EST_HRS_COLUMN).style.numFmt = '#,##0.0';
        worksheet.getColumn(ACT_HRS_COLUMN).style.numFmt = '#,##0.0';

        const rows = workboardReport.map((job) => [
            job.operator,
            job.formattedDate,
            job.workboardTitle,
            job.title,
            job.equipmentNames,
            job.route,
            job.mowingDirection,
            job.estDuration,
            job.actualDuration,
            job.formattedPreStartCheck,
            job.operatorNote
        ]);

        worksheet.addRows(rows);

        autofitWorksheet(worksheet);

        await downloadExcelFile(
            workbook,
            `Workboard-Job-Export${moment.utc().format('YYYY-MM-DD')}.xlsx`
        );
    } catch (error) {
        addToast({
            message: translateErrorMessage(`${EXPORT_FAIL}`, translations),
            type: TOAST_TYPE.ERROR
        });
    }
}

function getDateFormatAndReportType(dateSelector, timeScale) {
    switch (timeScale) {
        case 'day':
            return {
                format: 'YYYY-MM-DD',
                reportType: 'DailyReport'
            };
        case 'year':
            return {
                format: 'YYYY',
                reportType: 'YearlyReport'
            };
        default:
            return {
                format: 'MMMM-YYYY',
                reportType: 'MonthlyReport'
            };
    }
}

async function exportLaborWorkboardsReport({
    columnHeaderName,
    dateSelector,
    timeScale,
    title,
    translations,
    workboardsReport,
    hasViewFinancialDataPermission,
    addToast
}) {
    try {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(translations.ONLINK_WORKBOARDS, {
            views: [
                {
                    state: 'frozen',
                    ySplit: 1
                }
            ]
        });

        const header = worksheet.addRow(
            hasViewFinancialDataPermission ?
                [
                    columnHeaderName,
                    translations.ONLINK_EST_LABOR_HOURS,
                    translations.ONLINK_ESTIMATED_COST,
                    translations.ONLINK_ACTUAL_HOURS,
                    translations.ONLINK_ACTUAL_COST,
                    translations.ONLINK_OVERTIME_HOURS,
                    translations.ONLINK_OVERTIME_COST
                ] :
                [
                    columnHeaderName,
                    translations.ONLINK_EST_LABOR_HOURS,
                    translations.ONLINK_ACTUAL_HOURS,
                    translations.ONLINK_OVERTIME_HOURS
                ]
        );

        header.font = {
            bold: true
        };

        const rows = workboardsReport.map((row) =>
            hasViewFinancialDataPermission ?
                [
                    row.name,
                    row.estDuration,
                    row.estLaborCost,
                    row.actualDuration,
                    row.laborCost,
                    row.overtimeDuration,
                    row.overtimeCost
                ] :
                [
                    row.name,
                    row.estDuration,
                    row.actualDuration,
                    row.overtimeDuration
                ]
        );

        const {
            format, reportType
        } = getDateFormatAndReportType(
            dateSelector,
            timeScale
        );

        worksheet.addRows(rows);

        autofitWorksheet(worksheet);

        await downloadExcelFile(
            workbook,
            `${title}-${reportType}-${moment(dateSelector).format(format)}.xlsx`
        );
    } catch (error) {
        addToast({
            message: translateErrorMessage(`${EXPORT_FAIL}`, translations),
            type: TOAST_TYPE.ERROR
        });
    }
}

async function exportLaborByStatusDetailReport({
    timeScale,
    translations,
    dateSelect,
    laborData,
    tertiarySelector,
    addToast
}) {
    try {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(translations.ONLINK_LABOR_REPORT, {
            views: [
                {
                    state: 'frozen',
                    ySplit: 1
                }
            ]
        });

        const header = worksheet.addRow([
            translations.ONLINK_EMPLOYEE,
            translations.ONLINK_TOTAL
        ]);

        header.font = {
            bold: true
        };

        const rows = laborData.map((row) => [
            row.name,
            row.optionMap[tertiarySelector]
        ]);

        worksheet.addRows(rows);

        const {
            format,
            reportType
        } = getDateFormatAndReportType(dateSelect, timeScale);

        const total = laborData.reduce((totalValue, {optionMap}) => totalValue + optionMap[tertiarySelector], 0);

        const footerRow = worksheet.addRow([
            `${dataTableFormatTime(dateSelect, timeScale)} ${translations.ONLINK_TOTAL} - ${tertiarySelector}`,
            total
        ]);

        footerRow.font = {
            bold: true
        };

        autofitWorksheet(worksheet);

        await downloadExcelFile(workbook, `${translations.ONLINK_LABOR_REPORT}-${reportType}-${moment(dateSelect).format(format)}.xlsx`);
    } catch (error) {
        addToast({
            message: translateErrorMessage(`${EXPORT_FAIL}`, translations),
            type: TOAST_TYPE.ERROR
        });
    }
}

async function exportLaborByEmployeeDetailReport({
    timeScale,
    translations,
    dateSelect,
    laborData,
    tertiarySelector,
    addToast
}) {
    try {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(translations.ONLINK_LABOR_REPORT, {
            views: [
                {
                    state: 'frozen',
                    ySplit: 1
                }
            ]
        });

        const header = worksheet.addRow([
            translations.STATUS,
            translations.ONLINK_TOTAL
        ]);

        header.font = {
            bold: true
        };

        const [filteredData] = laborData.filter((data) => data.name === tertiarySelector);

        const keys = filteredData?.optionMap ? Object.keys(filteredData.optionMap) : [];
        const values = filteredData?.optionMap ? Object.values(filteredData.optionMap) : [];

        const rows = keys.map((key, index) => [
            key,
            values[index]
        ]);

        worksheet.addRows(rows);

        const {
            format,
            reportType
        } = getDateFormatAndReportType(dateSelect, timeScale);

        const footerRow = worksheet.addRow([
            `${dataTableFormatTime(dateSelect, timeScale)} ${translations.ONLINK_TOTAL} - ${tertiarySelector}`,
            values.reduce((totalValue, current) => totalValue + current, 0)
        ]);

        footerRow.font = {
            bold: true
        };

        autofitWorksheet(worksheet);

        await downloadExcelFile(workbook, `${translations.ONLINK_LABOR_REPORT}-${reportType}-${moment(dateSelect).format(format)}.xlsx`);
    } catch (error) {
        addToast({
            message: translateErrorMessage(`${EXPORT_FAIL}`, translations),
            type: TOAST_TYPE.ERROR
        });
    }
}

async function exportInventoryReport(partsData, translations, currencySymbol, addToast) {
    try {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(translations.ONLINK_INVENTORY, {
            views: [
                {
                    state: 'frozen',
                    ySplit: 1
                }
            ]
        });

        const header = worksheet.addRow([
            translations.TYPE,
            translations.NAME,
            translations.ONLINK_LOCATION,
            translations.MANUFACTURER,
            capitalizeFirstLetter(translations.PART_NUMBER),
            `${translations.VALUE} (${currencySymbol})`,
            translations.ONLINK_STOCK,
            `${translations.ONLINK_IN}/${translations.ONLINK_OUT}`
        ]);

        header.font = {
            bold: true
        };

        const rows = sortBy(partsData, [
            'partType',
            (partData) => partData?.name?.toLowerCase()
        ]).map((row) => [
            row.partType,
            row.name,
            row.binLocation,
            row.manufacturerName,
            row.partNumber,
            row.formattedTotalCost,
            row.formattedStock,
            row.formattedInOutValue
        ]);

        worksheet.addRows(rows);

        autofitWorksheet(worksheet);

        await downloadExcelFile(
            workbook,
            `Inventory-Export-${moment.utc().format('YYYY-MM-DD')}.xlsx`
        );
    } catch (error) {
        addToast({
            message: translateErrorMessage(`${EXPORT_FAIL}`, translations),
            type: TOAST_TYPE.ERROR
        });
    }
}

async function exportPartsHistoryReport(partsData, translations, addToast) {
    try {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(translations.HISTORY, {
            views: [
                {
                    state: 'frozen',
                    ySplit: 1
                }
            ]
        });

        const header = worksheet.addRow([
            translations.DATE,
            translations.NAME,
            capitalizeFirstLetter(translations.PART_NUMBER),
            translations.DETAILS,
            translations.ONLINK_PART_TYPE,
            translations.MANUFACTURER,
            translations.ONLINK_ADJUSTMENT
        ]);

        header.font = {
            bold: true
        };

        const rows = partsData
            .sort((a, b) => {
                const dateCompareResult = dateCompare(b.date, a.date);

                if (dateCompareResult === 0) {
                    return alphaNumericCompare(a.name, b.name);
                }

                return dateCompareResult;
            })
            .map((row) => [
                row.date,
                row.name,
                row.partNumber,
                `${row.detailsType} ${row.detailsName}`,
                row.partType,
                row.manufacturer,
                parseInt(row.adjustment, 10)
            ]);

        worksheet.addRows(rows);

        autofitWorksheet(worksheet);

        await downloadExcelFile(
            workbook,
            `Parts-History-Export-${moment.utc().format('YYYY-MM-DD')}.xlsx`
        );
    } catch (error) {
        addToast({
            message: translateErrorMessage(`${EXPORT_FAIL}`, translations),
            type: TOAST_TYPE.ERROR
        });
    }
}

async function exportEquipmentUptimeReport(
    rollupData,
    secondarySelector,
    translations,
    addToast
) {
    try {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(
            translations.ONLINK_EQUIPMENT_UPTIME,
            {
                views: [
                    {
                        state: 'frozen',
                        ySplit: 1
                    }
                ]
            }
        );

        const header = worksheet.addRow([
            getHeaderColumnName(secondarySelector, translations),
            translations.ONLINK_UPTIME,
            translations.ONLINK_DOWNTIME,
            translations.ONLINK_PERCENT
        ]);

        header.font = {
            bold: true
        };

        const {
            rollupsForSelector, totals
        } = rollupData;

        const rows = rollupsForSelector
            .sort((a, b) => alphaNumericCompare(a.name, b.name))
            .map((row) => [
                row.name,
                formatTwoDecimalPlaces(row.uptime),
                formatTwoDecimalPlaces(row.downtime),
                `${formatTwoDecimalPlaces(row.ratio)} ${UNITS.percent}`
            ]);

        worksheet.addRows(rows);

        if (totals) {
            const formattedDate = `${dataTableFormatTime(
                totals.dateSelect,
                totals.dateScale
            )} ${translations.ONLINK_TOTAL}`;
            const totalData = [
                formattedDate,
                formatTwoDecimalPlaces(totals.uptime),
                formatTwoDecimalPlaces(totals.downtime),
                `${formatTwoDecimalPlaces(totals.ratio)} ${UNITS.percent}`
            ];

            const totalRow = worksheet.addRow(totalData);

            totalRow.font = {
                bold: true
            };
        }

        autofitWorksheet(worksheet);

        await downloadExcelFile(
            workbook,
            `Equipment-Uptime-Export-${moment.utc().format('YYYY-MM-DD')}.xlsx`
        );
    } catch (error) {
        addToast({
            message: translateErrorMessage(`${EXPORT_FAIL}`, translations),
            type: TOAST_TYPE.ERROR
        });
    }
}

async function exportLifetimeUptimeReport(
    lifetimeData,
    primarySelector,
    translations,
    addToast
) {
    try {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(
            translations.ONLINK_LIFETIME_REPORT,
            {
                views: [
                    {
                        state: 'frozen',
                        ySplit: 1
                    }
                ]
            }
        );

        let header,
            rows;

        if (primarySelector === INVENTORIES) {
            header = worksheet.addRow([
                translations.ONLINK_PART_TYPE,
                translations.ONLINK_TOTAL_COST
            ]);
            rows = lifetimeData
                .sort((a, b) => alphaNumericCompare(a.name, b.name))
                .map((row) => [row.partType, row.formattedTotalCost]);
        } else {
            header = worksheet.addRow([
                translations.NAME,
                translations.BRAND,
                translations.MODEL,
                translations.ONLINK_JOINED,
                translations.ONLINK_PURCHASE,
                translations.ONLINK_TAX,
                translations.PARTS,
                translations.ONLINK_SERVICE,
                translations.ONLINK_TOTAL,
                translations.HOURS,
                translations.ONLINK_COST_PER_HOUR
            ]);
            rows = lifetimeData.sort((a, b) => defaultSortMethod(a.name, b.name))
                .map((row) => [
                    row.name,
                    row.brand,
                    row.model,
                    row.joined,
                    row.purchaseCostFormatted,
                    row.taxFormatted,
                    row.partsFormatted,
                    row.serviceFormatted,
                    row.totalFormatted,
                    row.engineHoursFormatted,
                    row.costPerEngineHourFormatted
                ]);
        }

        header.font = {
            bold: true
        };

        worksheet.addRows(rows);

        autofitWorksheet(worksheet);

        await downloadExcelFile(
            workbook,
            `Lifetime-Export-${moment.utc().format('YYYY-MM-DD')}.xlsx`
        );
    } catch (error) {
        addToast({
            message: translateErrorMessage(`${EXPORT_FAIL}`, translations),
            type: TOAST_TYPE.ERROR
        });
    }
}

async function exportEquipmentCostReport(rollupData, translations, addToast) {
    try {
        if (Object.keys(rollupData).length > 0) {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet(
                translations.ONLINK_EQUIPMENT_REPORT,
                {
                    views: [
                        {
                            state: 'frozen',
                            ySplit: 1
                        }
                    ]
                }
            );

            const header = worksheet.addRow([
                translations.DATE,
                translations.ONLINK_PURCHASE,
                translations.ONLINK_PROPERTY_TAX,
                translations.ONLINK_SALES_TAX,
                translations.PARTS,
                translations.ONLINK_SERVICE,
                translations.ONLINK_TOTAL
            ]);

            header.font = {
                bold: true,
                uppercase: true
            };

            const {
                dateChildren, ...totals
            } = rollupData;

            const rows = dateChildren
                .sort((a, b) => alphaNumericCompare(a.name, b.name))
                .map((row) => [
                    dataTableFormatTime(row.dateSelect, row.dateScale),
                    formatTwoDecimalPlaces(row.purchase),
                    formatTwoDecimalPlaces(row.propertyTax),
                    formatTwoDecimalPlaces(row.salesTax),
                    formatTwoDecimalPlaces(row.partsCost),
                    formatTwoDecimalPlaces(row.serviceCost),
                    formatTwoDecimalPlaces(row.totalCost)
                ]);

            worksheet.addRows(rows);

            const formattedDate = `${dataTableFormatTime(
                totals.dateSelect,
                totals.dateScale
            )} ${translations.ONLINK_TOTAL}`;
            const totalData = [
                formattedDate,
                formatTwoDecimalPlaces(totals.purchase),
                formatTwoDecimalPlaces(totals.propertyTax),
                formatTwoDecimalPlaces(totals.salesTax),
                formatTwoDecimalPlaces(totals.partsCost),
                formatTwoDecimalPlaces(totals.serviceCost),
                formatTwoDecimalPlaces(totals.totalCost)
            ];

            const totalRow = worksheet.addRow(totalData);

            totalRow.font = {
                bold: true
            };

            autofitWorksheet(worksheet);

            await downloadExcelFile(
                workbook,
                `Equipment-Cost-Export-${moment.utc().format('YYYY-MM-DD')}.xlsx`
            );
        }
    } catch (error) {
        addToast({
            message: translateErrorMessage(`${EXPORT_FAIL}`, translations),
            type: TOAST_TYPE.ERROR
        });
    }
}

async function exportServiceHistoryReport(rollupData, translations, addToast) {
    try {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(translations.SERVICE_HISTORY, {
            views: [
                {
                    state: 'frozen',
                    ySplit: 1
                }
            ]
        });

        const header = worksheet.addRow([
            translations.DATE,
            translations.ONLINK_ASSIGNEES,
            translations.EQUIPMENT_LIST,
            translations.MODEL,
            translations.ONLINK_GROUP,
            translations.ONLINK_SERVICE,
            replaceTranslationNames(translations.DURATION_WITH_UNIT, {
                '0': translations.mins
            }),
            translations.HOURS
        ]);

        header.font = {
            bold: true,
            uppercase: true
        };

        const rows = rollupData
            .sort((a, b) => {
                const dateA = moment(a.unFormattedTime);
                const dateB = moment(b.unFormattedTime);

                return dateB.diff(dateA);
            })
            .map((row) => [
                row.formattedTime,
                row.assignees,
                row.equipmentName,
                row.modelAndManufacturerName,
                row.serviceTypeName,
                row.serviceGroup,
                row.actualDuration,
                accounting.formatNumber(row.totalHours, 1)
            ]);

        worksheet.addRows(rows);

        autofitWorksheet(worksheet);

        await downloadExcelFile(
            workbook,
            `Service-History-Export-${moment.utc().format('YYYY-MM-DD')}.xlsx`
        );
    } catch (error) {
        addToast({
            message: translateErrorMessage(`${EXPORT_FAIL}`, translations),
            type: TOAST_TYPE.ERROR
        });
    }
}

export {
    exportInventoryReport,
    exportFleetReport,
    exportWorkboardReport,
    exportLaborWorkboardsReport,
    exportPartsHistoryReport,
    exportEquipmentUptimeReport,
    exportLaborByStatusDetailReport,
    exportLifetimeUptimeReport,
    exportEquipmentCostReport,
    exportServiceHistoryReport,
    exportLaborByEmployeeDetailReport
};
