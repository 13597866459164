// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {fetchEffectData, useDeepMemo} from 'Utils/react-utils';
import {getEquipmentRollupsForFleet} from 'Services/fleet-service';
import NoDataMessage from 'Ui/components/common/message/no-data-message';
import UptimeReportChart from 'Ui/features/onequip/reports/uptime-report/uptime-report-chart';
import UptimeReportDataTable from 'Ui/features/onequip/reports/uptime-report/uptime-report-data-table';
import {ONE_HUNDRED_PERCENT} from 'Ui/constants/common-constants';
import {sortBy} from 'lodash';
import {useNavBarActions} from 'Ui/react-hooks/use-navbar-actions';
import {IconDownload} from '@deere/icons';
import {exportEquipmentUptimeReport} from 'Services/excel-service';
import {VIEW_EQUIPMENT_DETAILS} from 'Common/constants/business-activities';

function getAggregatedRollups(rollupsForSelector) {
    const rollupsByName = rollupsForSelector.reduce((rollupMap, rollup) => {
        const {name} = rollup;

        if (!rollupMap.has(name)) {
            rollupMap.set(name, []);
        }

        rollupMap.get(name).push(rollup);

        return rollupMap;
    }, new Map());

    const aggregatedRollups = [];

    rollupsByName.forEach((rollupsForName) => {
        const aggregatedRollup = rollupsForName.reduce((aggregated, rollup) => {
            aggregated.downtime += rollup.downtime;
            aggregated.ratio += rollup.ratio;
            aggregated.uptime += rollup.uptime;

            return aggregated;
        }, {
            ...rollupsForName[0],
            downtime: 0,
            ratio: 0,
            uptime: 0
        });

        aggregatedRollup.ratio /= rollupsForName.length;

        aggregatedRollups.push(aggregatedRollup);
    });

    return sortBy(aggregatedRollups, 'name');
}

function getEquipmentUptimeRatio(equipmentRollup) {
    const {
        downtime,
        uptime
    } = equipmentRollup;

    return {
        ...equipmentRollup,
        ratio: uptime / (uptime + downtime) * ONE_HUNDRED_PERCENT
    };
}

function EquipmentUptimeReport(props) {
    const {
        dateSelect,
        membership,
        secondarySelector,
        setLoading,
        tertiarySelector,
        translations,
        loading,
        addToast
    } = props;

    const [equipmentRollups, setEquipmentRollups] = React.useState({});

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        const equipmentRollupsData = await getEquipmentRollupsForFleet(membership.fleetId, dateSelect);

        if (isMounted()) {
            setEquipmentRollups(equipmentRollupsData);
            setLoading((prevLoading) => ({
                ...prevLoading,
                primarySelector: false,
                dateSelect: false
            }));
        }
    }), [dateSelect, membership.fleetId]);

    const equipmentRollupsWithRatio = useDeepMemo(() => {
        const {
            [secondarySelector]: equipmentRollupsForSecondary = [],
            totals
        } = equipmentRollups;

        const rollupsForSelector = equipmentRollupsForSecondary.map(getEquipmentUptimeRatio);
        const aggregatedRollupsForSelector = getAggregatedRollups(rollupsForSelector);

        if (totals) {
            return {
                rollupsForSelector: aggregatedRollupsForSelector,
                totals: getEquipmentUptimeRatio(totals)
            };
        }

        return {
            rollupsForSelector: aggregatedRollupsForSelector
        };
    }, [equipmentRollups, secondarySelector]);

    useNavBarActions([{
        disabled: loading.equipment,
        Icon: <IconDownload
            iconDownload={{
                style: {
                    height: '20px',
                    width: '20px',
                    fill: '#fff'
                }
            }}
        />,
        onClick: () => exportEquipmentUptimeReport(equipmentRollupsWithRatio, secondarySelector, translations, addToast),
        myJdPermissions: VIEW_EQUIPMENT_DETAILS,
        title: 'EXPORT',
        variant: 'primary'
    }]);

    return (
        <NoDataMessage
            hasData={Object.keys(equipmentRollupsWithRatio.rollupsForSelector).length > 0}
            noDataMessage={translations.ONLINK_NO_DATA_GRAPH}
        >
            <div>
                <div className='chart-container'>
                    <UptimeReportChart
                        tertiarySelector={tertiarySelector}
                        translations={translations}
                        uptimeReportData={equipmentRollupsWithRatio}
                    />
                </div>
                <UptimeReportDataTable
                    secondarySelector={secondarySelector}
                    translations={translations}
                    uptimeReportData={equipmentRollupsWithRatio}
                />
            </div>
        </NoDataMessage>
    );
}

EquipmentUptimeReport.propTypes = {
    addToast: PropTypes.func,
    dateSelect: PropTypes.string,
    loading: PropTypes.object,
    membership: PropTypes.membership,
    secondarySelector: PropTypes.string,
    setLoading: PropTypes.func,
    tertiarySelector: PropTypes.string,
    translations: PropTypes.translations
};

export default EquipmentUptimeReport;
