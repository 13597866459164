// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import DataTable from 'Ui/components/common/data-table/data-table';
import EditLink from 'Ui/components/common/data-table/edit-link';
import PartsCatalogLink from 'Ui/components/common/data-table/parts-catalog-link';
import {closeDialog, openDialog, openDialog as openReduxDialog} from 'Store/actions/dialogs';
import {fetchEffectData} from 'Utils/react-utils';
import {formatLocalizedTime} from 'Utils/time-utils';
import {getFormattedCurrency} from 'Utils/unit-conversion-utils';
import {dateCompare, numericCompare} from 'Ui/models/maintenance';
import {getPartRequestsForInventories} from 'Services/inventory-service';
import {getInventories, getMembershipHierarchyDealerships} from 'Services/membership-service';
import {ACTIONS, CATEGORY, LABELS} from 'Utils/analytics-utils';
import moment from 'moment';
import dialogTypes from 'Ui/components/common/dialog-types';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {TOAST_TYPE} from '@deere/toast';
import {addToast as addToastRedux} from 'Store/actions/toasts';
import {deletePartRequest} from 'Services/parts-service';
import {ONLINK_NAVIGATION_REDESIGN} from 'Common/constants/feature-toggles';
import {useNavBarActions} from 'Ui/react-hooks/use-navbar-actions';
import {PARTS_CATALOG_URL, SHOP_URL} from 'Ui/components/secondary-navbar/utils/dropdown-navbar-helper';
import {MANAGE_PARTS, MODIFY_FINANCIAL_DATA, VIEW_FINANCIAL_DATA} from 'Common/constants/business-activities';
import {isAuthorized} from 'Common/utils/authorization-handler';
import {isShopDeereUrlEnabled} from 'Src/ui-core/pages/onequip/parts/inventory';

async function fetchPartRequests({
    featureToggles,
    isMounted = () => true,
    membership,
    setAvailableDistributors,
    setLoading,
    setPartRequests
}) {
    setLoading(true);

    const {inventories} = await getInventories();
    const [
        partRequestsData,
        dealerships
    ] = await Promise.all([
        getPartRequestsForInventories(inventories, 'recommended'),
        getMembershipHierarchyDealerships(membership.membershipId)
    ]);

    if (isMounted()) {
        const formattedPartRequests = partRequestsData.map((partRequest) => ({
            ...partRequest,
            due: formatLocalizedTime(moment(partRequest.dateSelect).toDate(), {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            }),
            formattedTotalCost: getFormattedCurrency(partRequest.totalCost, {
                currencyPreference: membership.currencyPreference,
                featureToggles,
                maximumFractionDigits: 2
            })
        }));

        setAvailableDistributors(dealerships.dealerships);
        setLoading(false);
        setPartRequests(formattedPartRequests);
    }
}

function Recommended(props) {
    const {
        featureToggles,
        membership,
        openPurchaseOrderDialog,
        translations,
        openConfirmation,
        closeConfirmation,
        addToast,
        myJdPermissions
    } = props;

    const [availableDistributors, setAvailableDistributors] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [partRequests, setPartRequests] = React.useState([]);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        await fetchPartRequests({
            featureToggles,
            isMounted,
            membership,
            setAvailableDistributors,
            setLoading,
            setPartRequests
        });
    }), [membership.membershipId]);

    const dialogOnClose = () => fetchPartRequests({
        featureToggles,
        membership,
        setAvailableDistributors,
        setLoading,
        setPartRequests
    });

    const handleDelete = async (partRequest) => {
        openConfirmation({
            message: replaceTranslationNames(translations.ONLINK_ITEM_THIS_CANNOT_BE_UNDONE, {
                '0': translations.ONLINK_PART_REQUEST.toLowerCase()
            }),
            title: replaceTranslationNames(translations.ONLINK_DELETE_ITEM, {
                '0': translations.ONLINK_PART_REQUEST
            }),
            async onContinue() {
                closeConfirmation();

                try {
                    await deletePartRequest(partRequest.partRequestId);

                    await fetchPartRequests({
                        featureToggles,
                        membership,
                        setAvailableDistributors,
                        setLoading,
                        setPartRequests
                    });
                } catch (e) {
                    addToast({
                        message: replaceTranslationNames(translations.ONLINK_CONFIRM_ITEM_DELETION_FAILED, {
                            '0': translations.ONLINK_PART_REQUEST.toLowerCase()
                        }),
                        type: TOAST_TYPE.ERROR
                    });
                }
            },
            onCancel: closeConfirmation
        });
    };

    const navBarActions = [
        {
            onClick: () => window.open(isShopDeereUrlEnabled(featureToggles, membership) ? SHOP_URL : PARTS_CATALOG_URL, '_blank'),
            title: 'ONLINK_PARTS_CATALOG',
            Icon: 'icon-new-window',
            variant: 'primary'
        }
    ];

    if (featureToggles[ONLINK_NAVIGATION_REDESIGN]) {
        useNavBarActions(navBarActions);
    }

    function getColumns() {
        return [
            {
                Header: translations.DUE,
                id: 'due',
                width: 90,
                accessor: (row) => row,
                sortMethod(a, b) {
                    return dateCompare(a.dateSelect, b.dateSelect);
                },
                Cell(row) {
                    return row.original.due;
                }
            },
            {
                Header: translations['Observation.QUANTITY'],
                accessor: 'quantity',
                className: 'right-aligned',
                headerClassName: 'right-aligned'
            },
            {
                Header: translations.NAME,
                accessor: 'name'
            },
            {
                Header: translations.MANUFACTURER,
                accessor: 'manufacturerName'
            },
            {
                Header: translations.PART_NUMBER,
                accessor: 'partNumber',
                Cell(row) {
                    return (
                        <PartsCatalogLink
                            analyticsEventData={{
                                action: ACTIONS.PARTS_INVENTORY,
                                category: CATEGORY.PARTS_CATALOG,
                                label: LABELS.PART_NUMBER,
                                value: row.original.partNumber
                            }}
                            manufacturerId={row.original.manufacturerId}
                            url={isShopDeereUrlEnabled(featureToggles, membership) ? `https://shop.deere.com/us/p/${row.original.partNumber}` :
                                `https://partscatalog.deere.com/jdrc/partdetails/partnum/${row.original.partNumber}`}
                            value={row.original.partNumber}
                        />
                    );
                }
            },
            {
                Header: translations.ONLINK_ESTIMATED_COST,
                accessor: (row) => row,
                myJdPermissions: VIEW_FINANCIAL_DATA,
                id: 'formattedTotalCost',
                className: 'right-aligned',
                width: 150,
                headerClassName: 'right-aligned',
                sortMethod(a, b) {
                    return numericCompare(a.totalCost, b.totalCost);
                },
                Cell(row) {
                    return row.original.formattedTotalCost;
                }
            },
            {
                accessor: 'name',
                myJdPermissions: MODIFY_FINANCIAL_DATA,
                className: 'right-aligned',
                resizable: false,
                sortable: false,
                Cell(row) {
                    return (
                        <EditLink
                            onClick={() => {
                                openPurchaseOrderDialog({
                                    availableDistributors,
                                    partRequest: row.original,
                                    translations,
                                    updatePartRequestData: dialogOnClose
                                });
                            }}
                        >
                            {translations.ONLINK_ADD_TO_PURCHASE_ORDER}
                        </EditLink>
                    );
                }
            }
        ].filter((option) => isAuthorized(
            {
                myJdPermissions: option.myJdPermissions
            }, {
                myJdPermissions,
                isMigrated: membership.isMigrated
            }
        ));
    }

    const canManageParts = isAuthorized(
        {
            myJdPermissions: MANAGE_PARTS
        }, {
            myJdPermissions,
            isMigrated: membership.isMigrated
        }
    );

    return (
        <DataTable
            columns={getColumns()}
            defaultSorted={[{
                desc: false,
                id: 'due'
            }]}
            deleteHandler={handleDelete}
            loading={loading}
            rows={partRequests}
            searchable={true}
            showDeleteColumn={canManageParts}
            showPagination={true}
            translations={translations}
        />
    );
}

Recommended.propTypes = {
    addToast: PropTypes.func,
    closeConfirmation: PropTypes.func,
    featureToggles: PropTypes.featureToggles,
    membership: PropTypes.membership,
    myJdPermissions: PropTypes.myJdPermissions,
    openConfirmation: PropTypes.func,
    openPurchaseOrderDialog: PropTypes.func,
    translations: PropTypes.translations
};

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addToastRedux(value));
        },
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        },
        openConfirmation(props) {
            dispatch(openDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        },
        openPurchaseOrderDialog(props) {
            dispatch(openReduxDialog(dialogTypes.PURCHASE_ORDER_DIALOG, props));
        }
    };
}

export default connect(null, mapDispatchToProps)(Recommended);
