// Unpublished Work © 2021-2024 Deere & Company.

export const ACCOUNT_MISMATCH = 'ACCOUNT_MISMATCH';
export const ERROR_OCCURRED_TITLE = 'ERROR_OCCURRED_TITLE';
export const INACTIVE_ACCOUNT = 'INACTIVE_ACCOUNT';
export const UNLINKED_ACCOUNT = 'UNLINKED_ACCOUNT';
export const EMAIL_ALREADY_EXISTS = 'Email already exists.';
export const EMAIL_FORMAT_INCORRECT = 'Email is not properly formatted.';
export const NAME_EXISTS = 'name exists';
export const REQUEST_LIMIT_EXCEEDED = 'Number of requests exceeded. Please try again in one minute.';
export const USER_INACTIVE_ACCESS = 'User is inactive.';
export const COURSE_NAME_TO_ID_MAP_EXISTS = 'CourseNameToIdMap already exists.';
export const USER_SESSION_NOT_FOUND = 'User session does not exist.';
export const CONFLICT_ERROR_MESSAGE = 'There is a conflict in organizations';
export const FORBIDDEN_ERROR_MESSAGE = 'You are forbidden from performing this action';
export const SERVICE_IN_PROCESS = 'Service is in process for equipment';
export const EXPORT_FAIL = 'Export failed';
export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;
export const NOT_FOUND = 404;
export const BAD_REQUEST = 400;
export const INTERNAL_SERVER_ERROR = 500;
export const CONFLICT = 409;
