// Unpublished Work © 2022-2024 Deere & Company.

import SecondaryNavDropdown from 'Ui/components/secondary-navbar/secondary-nav-dropdown';
import {
    onEquipBasePath,
    onLaborBasePath,
    onEquipPartsBasePath
} from 'Ui/constants/secondary-navbar-constants';
import {capitalizeFirstLetter} from 'Utils/translation-utils';
import appAccess from 'Common/constants/app-access';
import {ACTIONS, ANALYTICS_EVENTS, CATEGORY, LABELS, trackOnLinkEvent} from 'Utils/analytics-utils';
import {
    ASSIGNMENTS,
    EQUIPMENT_REPORT,
    FLEET,
    HOC,
    LABOR_REPORT,
    SERVICE_HISTORY,
    SUGGESTED_MAINTENANCE,
    WORKBOARD,
    INVENTORY,
    PARTS_RECOMMENDED,
    PARTS_ORDERS,
    PARTS_HISTORY
} from 'Common/constants/routes';
import {PARTS_HISTORY_PAGE, SHOP_DEERE_URL} from 'Common/constants/feature-toggles';
import {US} from 'Common/constants/pinpoint-constants';

const WORKBOARDS_NAVBAR_POSITION = 0;
const LABOR_REPORTS_NAVBAR_POSITION = 3;
const EQUIPMENT_NAVBAR_POSITION = 0;
const PARTS_NAVBAR_POSITION = 1;
const EQUIPMENT_REPORT_NAVBAR_POSITION = 2;
const PARTS_CATALOG_URL = 'https://partscatalog.deere.com/jdrc/search';
const SHOP_URL = 'https://shop.deere.com/us/search';

export function getOnLaborNavLinks(onlaborNavItems, featureToggles, translations) {
    const links = [
        {
            path: LABOR_REPORT,
            basePath: LABOR_REPORT,
            type: '_self',
            name: translations.Labor,
            reactRouter: true
        },
        {
            path: `${onLaborBasePath}/report/workboard`,
            basePath: `${onLaborBasePath}/report/workboard`,
            type: '_self',
            name: translations.ONLINK_WORKBOARD,
            reactRouter: true
        }
    ];

    onlaborNavItems[LABOR_REPORTS_NAVBAR_POSITION] = {
        basePath: `${onLaborBasePath}/report`,
        appAccess: appAccess.ONLABOR,
        dropdown: SecondaryNavDropdown,
        isDropdown: true,
        name: translations.reports_my_reports,
        links,
        reactRouter: true
    };

    onlaborNavItems[WORKBOARDS_NAVBAR_POSITION] = {
        path: WORKBOARD,
        basePath: WORKBOARD,
        appAccess: appAccess.ONLABOR,
        type: '_self',
        name: translations.ONLINK_WORKBOARDS,
        reactRouter: true
    };

    return onlaborNavItems.filter((navItem) => navItem.name !== 'SCHEDULE');
}

function getEquipmentDropdownNavLinks(translations) {
    return [
        {
            path: FLEET,
            basePath: FLEET,
            type: '_self',
            name: translations.ONLINK_FLEET,
            reactRouter: true
        },
        {
            path: ASSIGNMENTS,
            basePath: ASSIGNMENTS,
            name: translations.ASSIGNMENTS,
            type: '_self',
            reactRouter: true
        },
        {
            path: HOC,
            basePath: HOC,
            type: '_self',
            name: translations.ONLINK_HOC,
            reactRouter: true
        },
        {
            path: SUGGESTED_MAINTENANCE,
            basePath: SUGGESTED_MAINTENANCE,
            type: '_self',
            name: translations.ONLINK_MAINTENANCE,
            reactRouter: true
        },
        {
            path: `${onEquipBasePath}/shop`,
            basePath: `${onEquipBasePath}/shop`,
            name: translations.ONLINK_SHOP,
            type: '_self',
            reactRouter: true
        },
        {
            path: SERVICE_HISTORY,
            basePath: SERVICE_HISTORY,
            type: '_self',
            name: translations.ONLINK_SERVICE_HISTORY,
            reactRouter: true
        }
    ];
}

function getPartsDropdownNavLinks(translations, featureToggles, country, isMigrated) {
    const partCatalogUlr = isMigrated && featureToggles[SHOP_DEERE_URL] && country === US ?
        SHOP_URL :
        PARTS_CATALOG_URL;

    return [
        {
            path: INVENTORY,
            basePath: INVENTORY,
            type: '_self',
            name: capitalizeFirstLetter(translations.ONLINK_INVENTORY),
            reactRouter: true
        },
        {
            path: PARTS_HISTORY,
            basePath: PARTS_HISTORY,
            type: '_self',
            name: translations.HISTORY,
            reactRouter: true,
            toggle: PARTS_HISTORY_PAGE
        },
        {
            path: PARTS_RECOMMENDED,
            basePath: PARTS_RECOMMENDED,
            type: '_self',
            name: translations.ONLINK_RECOMMENDED,
            reactRouter: true
        },
        {
            path: PARTS_ORDERS,
            basePath: PARTS_ORDERS,
            type: '_self',
            name: translations.ORDERS,
            reactRouter: true
        },
        {
            path: partCatalogUlr,
            basePath: partCatalogUlr,
            type: '_blank',
            name: translations.ONLINK_PARTS_CATALOG,
            reactRouter: false,
            onClick: () => trackOnLinkEvent(ANALYTICS_EVENTS.TRACK_EVENT, {
                category: CATEGORY.PARTS_CATALOG,
                label: LABELS.PARTS_CATALOG,
                action: ACTIONS.FLEET_MENU,
                value: partCatalogUlr
            })
        }
    ];
}

export function getOnEquipNavLinks(onEquipNavItems, featureToggles, translations, country, isMigrated) {
    const equipmentLinks = getEquipmentDropdownNavLinks(translations);
    const partsLinks = getPartsDropdownNavLinks(translations, featureToggles, country, isMigrated);

    onEquipNavItems[PARTS_NAVBAR_POSITION] = {
        basePath: onEquipPartsBasePath,
        dropdown: SecondaryNavDropdown,
        isDropdown: true,
        name: translations.PARTS,
        links: partsLinks,
        reactRouter: true
    };

    onEquipNavItems[EQUIPMENT_NAVBAR_POSITION] = {
        basePath: onEquipBasePath,
        dropdown: SecondaryNavDropdown,
        isDropdown: true,
        name: translations.Equipment,
        links: equipmentLinks,
        reactRouter: true,
        prioritizeList: [onEquipPartsBasePath, EQUIPMENT_REPORT]
    };

    onEquipNavItems[EQUIPMENT_REPORT_NAVBAR_POSITION] = {
        path: EQUIPMENT_REPORT,
        basePath: EQUIPMENT_REPORT,
        name: translations.reports_my_reports,
        type: '_self',
        reactRouter: true
    };

    return onEquipNavItems;
}

export {
    PARTS_CATALOG_URL,
    SHOP_URL
};
