// Unpublished Work © 2023-2024 Deere & Company.

import {getStatusItems} from 'Utils/parts-utils';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {IconAdd} from '@deere/icons';
import React from 'react';
import {MultiSelect} from '@deere/isg.component-library';
import PropTypes from 'Utils/prop-type-utils';
import {ONLINK_NAVIGATION_REDESIGN} from 'Common/constants/feature-toggles';
import {useNavBarActions} from 'Ui/react-hooks/use-navbar-actions';
import {PARTS_CATALOG_URL, SHOP_URL} from 'Ui/components/secondary-navbar/utils/dropdown-navbar-helper';
import {MODIFY_FINANCIAL_DATA} from 'Common/constants/business-activities';
import {isShopDeereUrlEnabled} from 'Src/ui-core/pages/onequip/parts/inventory';

const ADD_ICON_STYLE = {
    style: {
        height: '20px',
        width: '20px',
        fill: '#fff'
    }
};

function OrdersFilterComponent(props) {
    const {
        translations,
        statusFilter,
        setStatusFilter,
        openAddPurchaseOrderDialog,
        inventories,
        featureToggles,
        loading,
        callGetPurchaseOrders,
        membership
    } = props;

    const onAddPurchaseOrderClick = () => openAddPurchaseOrderDialog({
        inventories,
        translations,
        featureToggles,
        onClose: callGetPurchaseOrders,
        membership
    });

    const navBarActions = [
        {
            disabled: loading,
            onClick: onAddPurchaseOrderClick,
            title: 'ONLINK_ADD_PURCHASE_ORDER',
            Icon: 'icon-add',
            variant: 'primary',
            requiredMyJdPermissions: MODIFY_FINANCIAL_DATA
        },
        {
            onClick: () => window.open(isShopDeereUrlEnabled(featureToggles, membership) ? SHOP_URL : PARTS_CATALOG_URL, '_blank'),
            title: 'ONLINK_PARTS_CATALOG',
            Icon: 'icon-new-window'
        }
    ];

    if (featureToggles[ONLINK_NAVIGATION_REDESIGN]) {
        useNavBarActions(navBarActions);
    }

    return (
        <div className={'extra-table-options'}>
            <div className={'status-filter'}>
                <MultiSelect
                    disableSorting={true}
                    hideFilter={false}
                    items={getStatusItems(translations)}
                    labels={{
                        allSelect: translations.ONLINK_ALL_STATUSES,
                        multiSelect: translations.ONLINK_STATUSES_SELECTED,
                        placeholder: translations.ONLINK_SELECT_STATUS,
                        selectAllItems: translations.ONLINK_ALL_STATUSES
                    }}
                    multiple={true}
                    name={'filterStatus'}
                    noSearch={true}
                    onChange={(ids) => setStatusFilter(ids)}
                    selectedIds={statusFilter}
                    tabIndex={0}
                />
            </div>
            {
                !featureToggles[ONLINK_NAVIGATION_REDESIGN] &&
                <OnlinkButton
                    className={'primary add-equipment-button'}
                    disabled={loading}
                    leftIcon={<IconAdd iconAdd={ADD_ICON_STYLE}/>}
                    onClick={onAddPurchaseOrderClick}
                >
                    {translations.ONLINK_ADD_PURCHASE_ORDER}
                </OnlinkButton>
            }
        </div>
    );
}

OrdersFilterComponent.propTypes = {
    callGetPurchaseOrders: PropTypes.func,
    featureToggles: PropTypes.featureToggles,
    inventories: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
    membership: PropTypes.membership,
    openAddPurchaseOrderDialog: PropTypes.func,
    setStatusFilter: PropTypes.func,
    statusFilter: PropTypes.arrayOf(PropTypes.string),
    translations: PropTypes.translations
};

export default OrdersFilterComponent;
