// Unpublished Work © 2022-2024 Deere & Company.

import React, {useCallback} from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import LaborReportFilters from 'OnLabor/report/labor-report-filters';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import Panel from 'Ui/components/panel/panel';
import ScheduleStatusDataTable from 'OnLabor/report/schedule-status-data-table';
import WorkboardBarChart from 'OnLabor/report/workboard-bar-chart';
import WorkboardDataTable from 'OnLabor/report/workboard-data-table';
import WorkboardLineChart from 'OnLabor/report/workboard-line-chart';
import WorkboardPieChart from 'OnLabor/report/workboard-pie-chart';
import {getTileData} from 'Services/panel-service';
import {fetchEffectData} from 'Utils/react-utils';
import {SCHEDULE_STATUS, WORKBOARDS} from 'Ui/components/graph/constants/graph-filters';
import {BY_STATUS, BY_TIME, BY_USER_STATUS, ID_TO_PROPERTY_MAP} from 'OnLabor/report/constants/labor-report';
import {COURSE} from 'Common/constants/membership-type';
import {clsx} from 'clsx';
import moment from 'moment';
import {sortBy} from 'lodash';
import Typography from '@mui/material/Typography';
import NoDataMessage from 'Ui/components/common/message/no-data-message';
import {useNavBarActions} from 'Ui/react-hooks/use-navbar-actions';
import {IconDownload} from '@deere/icons';
import {exportLaborByEmployeeDetailReport, exportLaborByStatusDetailReport} from 'Services/excel-service';
import {addToast as addToastRedux} from 'Store/actions/toasts';

const LOADING_ICON_PROPS = {
    className: 'dashboard-loading-icon',
    size: '50px'
};

function initializeStateAndRefs() {
    const [allData, setAllData] = React.useState(() => ({
        dateSelect: moment().format('YYYYMM'),
        laborReportData: {
            users: []
        }
    }));
    const [loading, setLoading] = React.useState({
        dateFilter: true,
        primarySelector: true
    });
    const [primarySelector, setPrimarySelector] = React.useState(WORKBOARDS);
    const [secondarySelector, setSecondarySelector] = React.useState(null);
    const [tertiarySelector, setTertiarySelector] = React.useState(null);
    const [titleDate, setTitleDate] = React.useState(null);

    return {
        allData,
        loading,
        primarySelector,
        secondarySelector,
        tertiarySelector,
        timeScale: React.useRef('month'),
        titleDate,
        setAllData,
        setLoading,
        setPrimarySelector,
        setSecondarySelector,
        setTertiarySelector,
        setTitleDate
    };
}

function aggregateDaysByStatusForUsers(users, initialStatuses, aggregateFunc) {
    return users.reduce((userMap, user) => {
        const {
            name,
            optionMap = {},
            ...otherProps
        } = user;

        if (!userMap.has(name)) {
            userMap.set(name, {
                ...otherProps,
                name,
                optionMap: {
                    ...initialStatuses
                }
            });
        }

        const userFromMap = userMap.get(name);

        Object.keys(optionMap).forEach((status) => {
            if (!userFromMap.optionMap[status]) {
                userFromMap.optionMap[status] = 0;
            }

            aggregateFunc(userFromMap, status, optionMap[status]);
        });

        return userMap;
    }, new Map());
}

function getAggregatedDaysByStatusForUsers(dateSelect, laborReportData, timeScale) {
    if (timeScale.current === 'month') {
        const initialStatuses = {
            Available: moment(dateSelect).daysInMonth()
        };

        return aggregateDaysByStatusForUsers(laborReportData.users, initialStatuses, (user, status, daysForStatus) => {
            if (status !== 'Available') {
                user.optionMap.Available -= daysForStatus;
                user.optionMap[status] += daysForStatus;
            }
        });
    }

    return aggregateDaysByStatusForUsers(laborReportData.users, {}, (user, status, daysForStatus) => {
        user.optionMap[status] += daysForStatus;
    });
}

function getLaborDataForTable({
    allData,
    membership,
    primarySelector,
    secondarySelector,
    timeScale
}) {
    const {
        dateSelect,
        laborReportData
    } = allData;

    if (primarySelector === SCHEDULE_STATUS) {
        const aggregatedDaysByStatus = getAggregatedDaysByStatusForUsers(dateSelect, laborReportData, timeScale);

        return Array.from(aggregatedDaysByStatus.values());
    }

    if (membership.membershipType === COURSE) {
        return laborReportData[ID_TO_PROPERTY_MAP[secondarySelector]] || [];
    }

    return laborReportData.totals ? [laborReportData.totals] : [];
}

function sortData({name}) {
    return name?.toLowerCase()?.trim();
}

function getLaborData({
    allData,
    membership,
    primarySelector,
    secondarySelector,
    timeScale
}) {
    const {laborReportData} = allData;

    const laborDataForTable = getLaborDataForTable({
        allData,
        membership,
        primarySelector,
        secondarySelector,
        timeScale
    });

    const sortedLaborDataForTable = sortBy(laborDataForTable, sortData);

    const laborDataForChart = secondarySelector === BY_TIME ? sortBy(laborReportData.users, sortData) : sortedLaborDataForTable;

    return [laborDataForChart, sortedLaborDataForTable];
}

function hasData(data) {
    return data?.length > 0;
}

function getChartComponent(primarySelector, secondarySelector) {
    if (primarySelector === WORKBOARDS && secondarySelector === BY_TIME) {
        return WorkboardLineChart;
    }

    if (primarySelector === SCHEDULE_STATUS && secondarySelector === BY_USER_STATUS) {
        return WorkboardPieChart;
    }

    return WorkboardBarChart;
}

function getDataTableComponent(primarySelector) {
    return primarySelector === WORKBOARDS ? WorkboardDataTable : ScheduleStatusDataTable;
}

function getUpdateDateFilterFunc({
    allData,
    loading,
    membership,
    primarySelector,
    setAllData,
    setLoading,
    timeScale
}) {
    const updateDateFilter = React.useCallback(async (selectedDateSelect, selectedTimeScale, isMounted = () => true) => {
        setLoading((prevLoading) => ({
            ...prevLoading,
            dateFilter: true
        }));

        const laborReportData = await getTileData(primarySelector, {
            date: selectedDateSelect
        });

        if (isMounted()) {
            timeScale.current = selectedTimeScale;

            setAllData({
                dateSelect: selectedDateSelect,
                laborReportData
            });

            if (selectedDateSelect) {
                setLoading({
                    dateFilter: false,
                    primarySelector: false
                });
            }
        }
    }, [primarySelector]);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        if (!loading.dateFilter) {
            setLoading((prevLoading) => ({
                ...prevLoading,
                primarySelector: true
            }));
            await updateDateFilter(allData.dateSelect, timeScale.current, isMounted);
        }
    }), [membership.membershipId, primarySelector]);

    return updateDateFilter;
}

function LaborReport(props) {
    const {
        featureToggles,
        membership,
        translations,
        addToast
    } = props;

    const {
        allData,
        loading,
        primarySelector,
        secondarySelector,
        tertiarySelector,
        timeScale,
        titleDate,
        setAllData,
        setLoading,
        setPrimarySelector,
        setSecondarySelector,
        setTertiarySelector,
        setTitleDate
    } = initializeStateAndRefs();

    const [isTimeAxis, ChartComponent, DataTableComponent] = React.useMemo(() => [
        secondarySelector?.includes(BY_TIME),
        getChartComponent(primarySelector, secondarySelector),
        getDataTableComponent(primarySelector)
    ], [primarySelector, secondarySelector]);

    const updateDateFilter = getUpdateDateFilterFunc({
        allData,
        loading,
        membership,
        primarySelector,
        secondarySelector,
        setAllData,
        setLoading,
        timeScale
    });

    const [laborDataForChart, laborDataForTable] = getLaborData({
        allData,
        membership,
        primarySelector,
        secondarySelector,
        timeScale
    });

    const className = clsx('graph-panel-wrapper', {
        hidden: loading.dateFilter || loading.primarySelector
    });

    const hasLaborDataForChart = hasData(laborDataForChart);
    const hasLaborDataForTable = hasData(laborDataForTable);
    const byStatus = secondarySelector === BY_STATUS || secondarySelector === BY_USER_STATUS;

    const handleExport = useCallback(() => {
        if (secondarySelector === BY_STATUS) {
            exportLaborByStatusDetailReport({
                laborData: laborDataForTable,
                timeScale: timeScale.current,
                dateSelect: allData.dateSelect,
                translations,
                tertiarySelector,
                addToast
            });
        } else {
            exportLaborByEmployeeDetailReport({
                laborData: laborDataForTable,
                timeScale: timeScale.current,
                dateSelect: allData.dateSelect,
                translations,
                tertiarySelector,
                addToast
            });
        }
    }, [secondarySelector, laborDataForChart, timeScale, allData]);

    useNavBarActions(byStatus && hasLaborDataForTable ?
        [{
            disabled: loading.dateFilter || loading.primarySelector,
            Icon: <IconDownload
                iconDownload={{
                    style: {
                        height: '20px',
                        width: '20px',
                        fill: '#fff'
                    }
                }}
            />,
            onClick: handleExport,
            title: 'EXPORT',
            variant: 'primary'
        }] : []);

    return (
        <LoadingWrapper
            {...LOADING_ICON_PROPS}
            alwaysRenderChildren={true}
            loading={loading.dateFilter || loading.primarySelector}
        >
            <Panel
                className={className}
                translations={translations}
            >
                <LaborReportFilters
                    laborReportData={laborDataForChart}
                    primarySelector={primarySelector}
                    secondarySelector={secondarySelector}
                    setTitleDate={setTitleDate}
                    tertiarySelector={tertiarySelector}
                    timeScale={timeScale.current}
                    translations={translations}
                    updateDateFilter={updateDateFilter}
                    updatePrimarySelector={(selected) => {
                        if (selected !== primarySelector) {
                            setLoading((prevLoading) => ({
                                ...prevLoading,
                                primarySelector: true
                            }));
                        }
                        setPrimarySelector(selected);
                    }}
                    updateSecondarySelector={setSecondarySelector}
                    updateTertiarySelector={setTertiarySelector}
                />
                <Typography
                    textAlign='center'
                    variant='h2'
                >{`${membership.name} ${translations.ONLINK_LABOR_REPORT} - ${titleDate}`}</Typography>
                <NoDataMessage
                    hasData={hasLaborDataForChart || hasLaborDataForTable}
                    noDataMessage={translations.ONLINK_NO_DATA_GRAPH}
                >
                    {
                        hasLaborDataForChart &&
                        <div className='chart-container'>
                            <ChartComponent
                                chartDataForSelector={laborDataForChart}
                                featureToggles={featureToggles}
                                isTimeAxis={isTimeAxis}
                                membership={membership}
                                primarySelector={primarySelector}
                                secondarySelector={secondarySelector}
                                tertiarySelector={tertiarySelector}
                                timeScale={timeScale.current}
                                translations={translations}
                            />
                        </div>
                    }
                    {
                        hasLaborDataForTable &&
                        <DataTableComponent
                            dateSelector={allData.dateSelect}
                            featureToggles={featureToggles}
                            isTimeAxis={isTimeAxis}
                            laborReportData={laborDataForTable}
                            membership={membership}
                            primarySelector={primarySelector}
                            secondarySelector={secondarySelector}
                            tertiarySelector={tertiarySelector}
                            timeScale={timeScale.current}
                            translations={translations}
                        />
                    }
                </NoDataMessage>
            </Panel>
        </LoadingWrapper>
    );
}

LaborReport.propTypes = {
    addToast: PropTypes.func,
    featureToggles: PropTypes.featureToggles,
    membership: PropTypes.membership,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        membership: state.membership
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addToastRedux(value));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LaborReport);
