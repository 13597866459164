// Unpublished Work © 2020-2024 Deere & Company.

export const VIEW_PARTNER_ORGS = 'VIEW_PARTNER_ORGS';
export const ELECTRIFICATION = 'ELECTRIFICATION';
export const ONLINK_NAVIGATION_HEADER = 'ONLINK_NAVIGATION_HEADER';
export const ONLINK_NAVIGATION_REDESIGN = 'ONLINK_NAVIGATION_REDESIGN';
export const ONLINK_CREATION_FLOW = 'ONLINK_CREATION_FLOW';
export const ONLABOR_CURRENT_WORKBOARD_WIDGET = 'ONLABOR_CURRENT_WORKBOARD_WIDGET';
export const WORKBOARD_REFACTOR = 'WORKBOARD_REFACTOR';
export const INTERNAL_PH_TOGGLE = 'INTERNAL_PH_TOGGLE';
export const JD_INVITE_SCREENS = 'JD_INVITE_SCREENS';
export const WORKBOARD_JOB_MULTILINE = 'WORKBOARD_JOB_MULTILINE';
export const INVENTORY_LOW_PARTS = 'INVENTORY_LOW_PARTS';
export const SERVICE_TICKET_INFO_ICON = 'SERVICE_TICKET_INFO_ICON';
export const PARTS_HISTORY_PAGE = 'PARTS_HISTORY_PAGE';
export const MOWING_DIRECTION_ROW = 'MOWING_DIRECTION_ROW';
export const EQUIPMENT_EXPENSE_EDIT = 'EQUIPMENT_EXPENSE_EDIT';
export const PIN_LOCATION = 'PIN_LOCATION';
export const SERVICE_TICKET_EDIT = 'SERVICE_TICKET_EDIT';
export const EDIT_MODEL = 'EDIT_MODEL';
export const SERVICE_TICKET_PRINT = 'SERVICE_TICKET_PRINT';
export const SELECT_BY_GROUP = 'SELECT_BY_GROUP';
export const TECHNICIAN_FILTER = 'TECHNICIAN_FILTER';
export const SHOP_DEERE_URL = 'SHOP_DEERE_URL';
